import { Link } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"

const DriverDetails = () => {
    return (<>
        <Header />
        <SideBar />
        <main class="main">




            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                        <DashboardSideBar />
                        <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="user-profile-card">
                                            <div class="user-profile-card-header">
                                                <h4 class="user-profile-card-title d-flex align-items-center justify-content-between w-100"><span> Driver Detail #234</span> <Link to={'/car/edit/2023'} className="theme-btn">Edit Details</Link></h4>

                                            </div>
                                            <div class="row">

                                                <div class="col-md-12 mx-auto p-0">
                                                    <div class="booking-form shadow-none">
                                                    <div class="team-single-wrapper">
                                                            <div class="row align-items-center">
                                                                <div class="col-lg-4">
                                                                    <div class="team-single-img">
                                                                        <img src="/assets/img/team/02.jpg" className="img-fluid" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-7">
                                                                    <div class="team-single-content">
                                                                        <div class="team-single-name">
                                                                            <h3>Kristen F. Cantor</h3>
                                                                            <p>Senior Driver</p>
                                                                        </div>
                                                                        <div class="team-single-detail">
                                                                            <p>There are many variations of passages available but the majority have suffered
                                                                                alteration in some form </p>
                                                                        </div>
                                                                        <div class="team-single-info">
                                                                            <ul>
                                                                                <li>
                                                                                    <span class="team-single-info-left"><i class="far fa-phone"></i>
                                                                                        Phone:</span>
                                                                                    <span class="team-single-info-right">+2 123 654 7898</span>
                                                                                </li>
                                                                                <li>
                                                                                    <span class="team-single-info-left"><i class="far fa-envelope"></i>
                                                                                        Email:</span>
                                                                                    <span class="team-single-info-right"><a
                                                                                        href="https://live.themewild.com/cdn-cgi/l/email-protection"
                                                                                        class="__cf_email__"
                                                                                        data-cfemail="4920272f26092c31282439252c672a2624">[email&#160;protected]</a></span>
                                                                                </li>
                                                                                <li>
                                                                                    <span class="team-single-info-left"><i class="far fa-tools"></i>
                                                                                        Experience:</span>
                                                                                    <span class="team-single-info-right">15 Years</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <hr />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="user-profile-card-header mt-5">
                                                            <h4 class="user-profile-card-title">Assigned Cab Details</h4>

                                                        </div>
                                                        <div class="col-md-12 col-lg-12 filter-item cat1 cat2">
                                                            <div class="row align-items-center ">
                                                                <Link to={'/taxi/53454'} className="col-md-4 col-12"><div class="taxi-img">
                                                                    <img src="/assets/img/taxi/01.png" width={'350px'} />
                                                                </div></Link>
                                                                <div class="taxi-content col-md-7 col-12">
                                                                    <div class="taxi-head">
                                                                        <span> <b>Sedan</b></span><h4>Toyota Etios</h4>

                                                                    </div>
                                                                    <div class="taxi-feature">
                                                                        <ul>
                                                                            <li><i class="far fa-car-side-bolt"></i> Charges Per KM: <span>10</span></li>
                                                                            <li><i class="far fa-car-side-bolt"></i> Model: <span>2019</span></li>
                                                                            <li><i class="far fa-person-seat"></i> Passengers: <span>4</span></li>
                                                                            <li><i class="far fa-suitcase-rolling"></i> Luggage Carry: <span>2 Bags</span></li>
                                                                            <li><i class="far fa-heat"></i> Air Condition: <span>Yes</span></li>
                                                                            <li><i class="far fa-map-location-dot"></i> Valid Insurance: <span>Yes / #5345435345</span>
                                                                            </li>
                                                                            <li><i class="far fa-user-pilot"></i> Car Number: <span>MP-09-CQ-2034</span></li>
                                                                        </ul>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                      


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer />
    </>
    )
}
export default DriverDetails
/* eslint-disable no-async-promise-executor */
import Axios from "axios";
import constant from "../constant";
import api from "../api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GET_ORDER_BY_ID: (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.order.GET_ORDER_BY_ID(id),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  UPDATE_ORDER: (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.order.UPDATE_ORDER(id),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPDATE_ORDER_BY: (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.order.UPDATE_ORDER_BY(id),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  GET_ORDER: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.order.GET_ORDER()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  BOOKING_REQUESTS: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.order.BOOKING_REQUESTS()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  BOOKING_LISTING: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.order.BOOKING_LISTING()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  BOOKING_DETAIL: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.order.BOOKING_DETAIL(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  ACCEPT_BOOKING: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.order.ACCEPT_BOOKING(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  REJECT_BOOKING: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.order.REJECT_BOOKING(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
};

import Header from '../../components/header'
import Footer from '../../components/footer'
import SideBar from '../../components/sidebar'
const register = () => {
return (<>
    <Header/>
    <SideBar/>
    <main class="main">

    <div class="site-breadcrumb" style={{background: 'url(assets/img/breadcrumb/01.jpg)'}}>
        <div class="container">
            <h2 class="breadcrumb-title">Register with Cars53</h2>
            <ul class="breadcrumb-menu">
                <li><a href="index.html">Home</a></li>
                <li class="active">Register</li>
            </ul>
        </div>
    </div>


    <div class="login-area py-5">
        <div class="container">
            <div class="col-md-5 mx-auto">
                <div class="login-form">
                    <div class="login-header">
                        <img src="/assets/img/logo/logo.png" alt/>
                        <p>Create your Cars53 account</p>
                    </div>
                    <form action="#">
                        <div class="form-group">
                            <label>Full Name</label>
                            <input type="text" class="form-control" placeholder="Your Name"/>
                        </div>
                        <div class="form-group">
                            <label>Email Address</label>
                            <input type="email" class="form-control" placeholder="Your Email"/>
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" class="form-control" placeholder="Your Password"/>
                        </div>
                        <div class="form-check form-group">
                            <input class="form-check-input" type="checkbox" value id="agree"/>
                            <label class="form-check-label" for="agree">
                                I agree with the <a href="#">Terms Of Service.</a>
                            </label>
                        </div>
                        <div class="d-flex align-items-center">
                            <button type="submit" class="theme-btn"><i class="far fa-paper-plane"></i>
                                Register</button>
                        </div>
                    </form>
                    <div class="login-footer">
                        <p>Already have an account? <a href="login.html">Login.</a></p>
                        <div class="social-login">
                            <p>Continue with social media</p>
                            <div class="social-login-list">
                                <a href="#"><i class="fab fa-facebook-f"></i></a>
                                <a href="#"><i class="fab fa-google"></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</main>
<Footer/>
</>
)
}
export default register
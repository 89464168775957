import { Link } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import services from "../../services"
import { useEffect, useState } from "react"
import moment from 'moment'
import Toaster from "../../components/toaster";

const Drivers = () => {
    const [driverList, setDriverList] = useState()
    const [toaster, setToaster] = useState({ visiblity: "hide" });
    const getDriverData = async () => {
        try {
            const response = await services.Vendor.GET_DRIVER();
            console.log(response)
            setDriverList(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    const activehandler = async(e, item, index) =>{
        console.log(e)
        try {
            const response = await services.Vendor.UPDATE_DRIVER(item.id, {userData : {isActive :e.target.checked }});
            console.log(response)
            getDriverData()
        } catch (error) {
            console.log(error)
            setToaster({
                type: "danger",
                title: "Error Occured",
                text: error.response.data.message,
                visiblity: "show",
            });
            setTimeout(() => {
                setToaster({
                    visiblity: "hide",
                });

            }, 1500)
        }
    }
    useEffect(() => {
        getDriverData()
    }, [])
    return (<>
        <Header />
        <SideBar />
        <main class="main">

        <Toaster
                type={toaster.type}
                title={toaster.title}
                text={toaster.text}
                visiblity={toaster.visiblity}
            />
    


            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                        <DashboardSideBar />
                        <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="user-profile-card">
                                            <div class="user-profile-card-header">
                                                <h4 class="user-profile-card-title">My Drivers</h4>
                                                <div class="user-profile-card-header-right">
                                                    <div class="user-profile-search">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" placeholder="Search..." />
                                                            <i class="far fa-search"></i>
                                                        </div>
                                                    </div>
                                                    <Link to={'/adddriver'} class="theme-btn"><i class="far fa-plus"></i> Add Driver</Link>
                                                </div>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table text-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Driver Info</th>

                                                            <th>Cab Assigned</th>
                                                            <th>Phone number</th>
                                                            <th>Aadhar and DR License</th>
                                                            <th>Created Date</th>
                                                            <th>Approval Status</th>
                                                            <th>Active Status</th>
                                                            {/* <th>Action</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {driverList && driverList.length ? driverList.map((item, indx) => {
                                                            return (

                                                                <tr key={indx}>
                                                                    <td>
                                                                        <div class="table-list-info">
                                                                            <a href="#">

                                                                                <div class="table-list-content">
                                                                                    <h6>{item.firstName} {item.lastName}</h6>
                                                                                    {/* <span>Rs. 10/KM</span> */}
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="table-list-info">
                                                                            <a href="#">

                                                                                {item.DriverCar ? <div class="table-list-content">
                                                                                    <h6>{item.DriverCar.VendorCar.Car.name}</h6>
                                                                                    <span>{item.DriverCar.VendorCar.rcNumber}</span>
                                                                                </div> : '-'}
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span>{item.phoneNumber}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{item.DriverDetail.aadharNumber}</span> <br />
                                                                        <span>{item.DriverDetail.drivingLicenceNumber}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{moment(item.createdAt).format('DD-MM-YYYY,hh:mm')}</span>
                                                                    </td>

                                                                    <td><span class={`badge badge-${item.DriverDetail.approvalStatus == 'approved' ? 'success' : 'danger'} text-capitalize`}>{item.DriverDetail.approvalStatus}</span></td>

                                                                    <td>
                                                                        <div class="form-check form-switch">
                                                                            <input checked={item.isActive} onChange={(e)=>activehandler(e, item, indx)} class="form-check-input" type="checkbox" role="switch" />
                                                                            <label class="form-check-label" for="flexSwitchCheckDefault"><span class={`badge badge-${item.isActive ? 'success' : 'danger'} text-capitalize`}>{item.isActive ? 'Active' : 'Inactive'}</span></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) : null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="pagination-area d-none">
                                                <div aria-label="Page navigation example">
                                                    <ul class="pagination my-3">
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" aria-label="Previous">
                                                                <span aria-hidden="true"><i
                                                                    class="far fa-angle-double-left"></i></span>
                                                            </a>
                                                        </li>
                                                        <li class="page-item active"><a class="page-link" href="#">1</a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" aria-label="Next">
                                                                <span aria-hidden="true"><i
                                                                    class="far fa-angle-double-right"></i></span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer />
    </>
    )
}
export default Drivers
import Axios from "axios";
import constant from "../constant";
import api from "../api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CREATE_CAR: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Cars.CREATE_CAR(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  GET_CAR: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Cars.GET_CAR()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  GET_VENDOR_CAR: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Cars.GET_VENDOR_CAR()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_VENDOR_CAR_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Cars.GET_VENDOR_CAR_ID(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPDATE_CAR: (id,data) => {
    return new Promise(async (resolve, reject) => {

      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Cars.UPDATE_CAR_BY_ID(id),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPLOAD_IMAGE: ( data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Cars.UPLOAD_IMAGE(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
 
}
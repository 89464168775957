import { Link, useNavigate } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import services from "../../services"
import Toaster from "../../components/toaster"
import { useEffect, useState } from "react"
import constant from "../../constant"

const MyProfile = () => {
    const [profileDetails, setProfileDetails] = useState()
    const [toaster, setToaster] = useState({ visiblity: "hide" });
const navigate = useNavigate();
    const getMyProfile = async () => {
        try {
            const response = await services.Vendor.GET_VENDOR();
            if (response.data) {
                setProfileDetails(response.data);
            }
        } catch (error) {
            console.log(error)
        }
    }
    const HandleOnChangeValue = (e, key, type) => {
        let obj = { ...profileDetails }
        if (type) {
            obj[key] = e;
        } else {
            obj.VendorDetail[key] = e;
        }

        setProfileDetails({ ...obj })
    }
    useEffect(() => {
        getMyProfile();
    }, [])
    const uploadBusinessImage = async (e, type) => {
        const formData = new FormData();
        let categoryImage = e.target.files[0];
        formData.append("file", categoryImage);

        try {
            const response = await services.Cars.UPLOAD_IMAGE(formData);
            console.log(response)
            if (type == 'qr') {
                HandleOnChangeValue([response.data.url], 'documents')
            } else {
                HandleOnChangeValue(response.data.url, 'businessLogo')
            }
        } catch (error) {
            console.log(error);
        }

    };
    const onSubmitHandler = async () => {
        try {
            let data = {
                userData: {
                    firstName: profileDetails.firstName,
                    lastName: profileDetails.lastName,
                    phoneNumber: profileDetails.phoneNumber,
                    email: profileDetails.email
                },
                vendorData: {
                    alternativePhoneNumber: profileDetails.VendorDetail.alternativePhoneNumber,
                    aadharLinkedPhoneNumber: profileDetails.VendorDetail.aadharLinkedPhoneNumber,
                    aadharNumber: profileDetails.VendorDetail.aadharNumber,
                    documents: profileDetails.VendorDetail.documents,
                    registerationNumber: profileDetails.VendorDetail.registerationNumber,
                }
            }
            if(!profileDetails.VendorDetail.registerationNumber) {
                delete data.vendorData.registerationNumber ;
            } 
            const response = await services.Vendor.UPDATE_VENDOR(data)
            setToaster({
                type: "success",
                title: "Login",
                text: 'Profile Updated successfully',
                visiblity: "show",
            });
            setTimeout(() => {
                setToaster({
                    visiblity: "hide",
                });
                navigate('/')
            }, 3000)
        } catch (error) {
            console.log(error)
            setToaster({
                type: "danger",
                title: "Error",
                text: error.response.data.message,
                visiblity: "show",
            });
            setTimeout(() => {
                setToaster({
                    visiblity: "hide",
                });
            }, 60000)
        }
    }
    return (<>
        <Header />
        <SideBar />
        <main class="main">
            <Toaster
                type={toaster.type}
                title={toaster.title}
                text={toaster.text}
                visiblity={toaster.visiblity}
            />
            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                        <DashboardSideBar />
                        <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="user-profile-card">
                                            <div >
                                                <h4 class="user-profile-card-title d-flex justify-content-between">Profile Info <span class={`badge text-capitalize badge-${profileDetails?.VendorDetail?.approvalStatus == 'approved' ? 'success' : 'danger'}`}>{profileDetails?.VendorDetail?.approvalStatus}</span></h4>

                                            </div>
                                            <div class="user-profile-form">

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>First Name</label>
                                                            <input onChange={(e) => HandleOnChangeValue(e.target.value, 'firstName', 'user')} type="text" class="form-control" value={profileDetails?.firstName}
                                                                placeholder="First Name" />
                                                                {!profileDetails?.firstName && <small className="text-danger">First Name required</small>}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Last Name</label>
                                                            <input onChange={(e) => HandleOnChangeValue(e.target.value, 'lastName', 'user')} type="text" class="form-control" value={profileDetails?.lastName}
                                                                placeholder="Last Name" />
                                                                {!profileDetails?.lastName && <small className="text-danger">Last Name required</small>}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Phone Number</label>
                                                            <input disabled={true} onChange={(e) => HandleOnChangeValue(e.target.value, 'phoneNumber', 'user')} type="number" class="form-control"
                                                                value={profileDetails?.phoneNumber} placeholder="Phone number" />
                                                                {profileDetails?.phoneNumber?.length > 10 && <small className="text-danger">Please enter valid phone number</small>}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Alternative Phone Number</label>
                                                            <input onChange={(e) => HandleOnChangeValue(e.target.value, 'alternativePhoneNumber')} type="number" class="form-control"
                                                                value={profileDetails?.VendorDetail?.alternativePhoneNumber} placeholder="Alternative Phone number" />
                                                                {profileDetails?.VendorDetail?.alternativePhoneNumber?.length > 10 && <small className="text-danger">Please enter valid number</small>}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Aadhar Number</label>
                                                            <input disabled={profileDetails?.VendorDetail?.approvalStatus == 'approved'}  onChange={(e) => HandleOnChangeValue(e.target.value, 'aadharNumber')} type="number" class="form-control"
                                                                value={profileDetails?.VendorDetail?.aadharNumber} placeholder="Aadhar Number" />
                                                                {(profileDetails?.VendorDetail?.aadharNumber?.length < 12 || profileDetails?.VendorDetail?.aadharNumber?.length > 12) && <small className="text-danger">Please enter valid aadhar number</small>}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Aadhar Link Number</label>
                                                            <input disabled={profileDetails?.VendorDetail?.approvalStatus == 'approved'} onChange={(e) => HandleOnChangeValue(e.target.value, 'aadharLinkedPhoneNumber')} type="number" class="form-control"
                                                                value={profileDetails?.VendorDetail?.aadharLinkedPhoneNumber} placeholder="Aadhar Linked Number " />
                                                                {(profileDetails?.VendorDetail?.aadharLinkedPhoneNumber?.length > 10 || profileDetails?.VendorDetail?.aadharLinkedPhoneNumber?.length < 10) && <small className="text-danger">Please enter valid number</small>}
                                                        </div>
                                                    </div>
                                              
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Email</label>
                                                            <input type="email" class="form-control"
                                                                onChange={(e) => HandleOnChangeValue(e.target.value, 'email','user')} value={profileDetails?.email} placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Business Registration Number</label>
                                                            <input disabled={profileDetails?.VendorDetail?.approvalStatus == 'approved'} type="email" class="form-control"
                                                                onChange={(e) => HandleOnChangeValue(e.target.value, 'registerationNumber')} value={profileDetails?.VendorDetail?.registerationNumber} placeholder="Registeration Number" />
                                                        </div>
                                                    </div>
                                            

                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Upload Payment QR</label>
                                                            <input onChange={(e) => uploadBusinessImage(e, 'qr')} type="file" class="form-control"
                                                                placeholder="QR" />
                                                        </div>
                                                        {profileDetails?.VendorDetail?.documents && profileDetails?.VendorDetail?.documents.length && profileDetails?.VendorDetail?.documents[0] &&
                                                            <img style={{maxHeight: '200px'}} crossorigin="anonymous" src={constant.IMAGE_URL + profileDetails?.VendorDetail?.documents[0]} />
                                                        }
                                                    </div>
                                                </div>
                                                <button type="button" onClick={onSubmitHandler} class="theme-btn my-3"><span
                                                    class="far fa-user"></span> Save Changes</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer />
    </>
    )
}
export default MyProfile
import { Link } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import services from "../../services"
import { useEffect, useState } from "react"
import moment from "moment"
import { Rating } from 'react-simple-star-rating'
const MyRatings = () => {
    const [bookingList, setBookingList] = useState([])
    useEffect(() => {
        getBookingsHandler();
    }, [])
    const getBookingsHandler = async () => {
        try {
            const response = await services.order.BOOKING_LISTING();

            let data = response.data;
            if (data.length) {
                data.map((item) => {
                    item.BookingDetails.sort(function (a, b) {
                        return parseFloat(a.id) - parseFloat(b.id);
                    });
                })
                var newArray = data.filter(function (el) {
                    return el.BookingDetails[0].bookingStatus == 'confirmed' || el.BookingDetails[0].bookingStatus == 'started' || el.BookingDetails[0].bookingStatus == 'ended';
                });
                console.log(newArray)
                setBookingList(newArray)
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (<>
        <Header />
        <SideBar />
        <main class="main">




            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                        <DashboardSideBar />
                        <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="user-profile-card">
                                            <div class="user-profile-card-header">
                                                <h4 class="user-profile-card-title">My Booking Reviews</h4>
                                                <div class="user-profile-card-header-right">
                                                    <div class="user-profile-search">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" placeholder="Search..." />
                                                            <i class="far fa-search"></i>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table text-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th className="">Cab Info</th>
                                                            
                                                            <th>Pickup and drop</th>
                                                            <th>Rating</th>
                                                            <th>Review</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bookingList?.map((item) => {
                                                            return (<tr key={item.id}>
                                                                <td>
                                                                    <div class="table-list-info">
                                                                        <a href="#">
                                                                            {/* <img src="/assets/img/taxi/01.png" alt/> */}
                                                                            <div class="table-list-content">
                                                                                <h6>{item.Car.brand} {item.Car.name}</h6>
                                                                                <span>Booking ID: #{item.id}</span>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </td>
                                                               
                                                                <td>
                                                                    <span>{item?.BookingDetails[0].pickupLocation.addressLine}</span> <br />
                                                                    <span>{item?.BookingDetails[0].dropLocation.addressLine}</span>
                                                                    {/* <p>03:15 PM</p> */}
                                                                </td>
                                                                <td><Rating
                                                                    initialValue={item.BookingReviews[0].rating}
                                                                /></td>
                                                                <td>{item.BookingReviews[0].comment}</td>
                                                                <td>
                                                                    <Link to={`/booking/${item.id}`}
                                                                        class="btn btn-outline-secondary btn-sm rounded-2"
                                                                        data-bs-toggle="tooltip" title="Details"><i
                                                                            class="far fa-eye"></i></Link>
                                                                </td>
                                                            </tr>)
                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="pagination-area d-none">
                                                <div aria-label="Page navigation example">
                                                    <ul class="pagination my-3">
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" aria-label="Previous">
                                                                <span aria-hidden="true"><i
                                                                    class="far fa-angle-double-left"></i></span>
                                                            </a>
                                                        </li>
                                                        <li class="page-item active"><a class="page-link" href="#">1</a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" aria-label="Next">
                                                                <span aria-hidden="true"><i
                                                                    class="far fa-angle-double-right"></i></span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer />
    </>
    )
}
export default MyRatings
/* eslint-disable import/no-anonymous-default-export */
export default {
  GET_ORDER_BY_ID:(id)=>`/order/${id}`,
  UPDATE_ORDER: (id) => `/orderDetails/${id}`,
  GET_ORDER: () => `/order`,
  UPDATE_ORDER_BY:(id)=>`/order/${id}`,
  BOOKING_REQUESTS:()=>`/vendor/bookings/getBookingRequests`,
  BOOKING_LISTING:()=>`/vendor/bookings/getMyBookings`,
  ACCEPT_BOOKING:()=>`/vendor/bookings/confirmRequest`,
  REJECT_BOOKING:(id)=>`/vendor/bookings/rejectBooking/${id}`,
  BOOKING_DETAIL:(id)=>`/vendor/bookings/getBookingDetail/${id}`,
  
};
  
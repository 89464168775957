import Axios from "axios";
import constant from "../constant";
import api from "../api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GET_VENDOR: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Vendor.GET_VENDOR()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_DRIVER: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Vendor.GET_DRIVER()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  ADD_DRIVER: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Vendor.ADD_DRIVER(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  UPDATE_VENDOR: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Vendor.UPDATE_VENDOR(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPDATE_DRIVER: (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Vendor.UPDATE_DRIVER(id),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },


};

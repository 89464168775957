import { Link,useNavigate,useParams } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import { useEffect, useState } from "react"
import services from "../../services"
import constant from "../../constant";
import Toaster from "../../components/toaster";
import "react-toggle/style.css" // for ES6 modules
import Toggle from 'react-toggle'
const EditCar = ()=>{
    let navigate = useNavigate();
    const [carsList, setCarsList] = useState()
    const [carData, setCarData] = useState()
    const [driverList, setDriverList] = useState()
    const [selectedDriver, setSelectedDriver] = useState()

    const [isAcAvailable, setIsAcAvailable] = useState(false)
    const [isCarrierAvailable, setIsCarrierAvailable] = useState(false)
    const [toaster, setToaster] = useState({ visiblity: "hide" });
    let { id } = useParams();
    const getMasterCars = async() =>{
        try {
            const response = await services.Cars.GET_CAR();
            console.log(response)
            setCarsList(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getCarDetail = async(id) =>{
        try {
            const response = await services.Cars.GET_VENDOR_CAR_ID(id);
            console.log(response)
            setCarData(response.data)
            setIsAcAvailable(response.data.isAcAvailable == null ? false : response.data.isAcAvailable)
            setIsCarrierAvailable(response.data.isCarrierAvailable == null ? false : response.data.isCarrierAvailable)
        } catch (error) {
            console.log(error)
        }
    }
    const HandleOnChangeValue = (e, key) => {
        let obj = { ...carData }
            obj[key] = e;
        setCarData({ ...obj })
    }
    const getDriverData = async() =>{
        try {
            const response = await services.Vendor.GET_DRIVER();
            console.log(response)
            setDriverList(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    const uploadBusinessImage = async (e, type) => {
        const formData = new FormData();
        let categoryImage = e.target.files[0];
        formData.append("file", categoryImage);

        try {
            const response = await services.Cars.UPLOAD_IMAGE(formData);
            console.log(response)
            if (type == 'car') {
                let arr = carData.photos || []
                arr.push(response.data.url)
                HandleOnChangeValue(arr, 'photos')
            } else {
                let arr = carData.docs || []
                arr.push(response.data.url)
                HandleOnChangeValue(arr, 'docs')
            }
        } catch (error) {
            console.log(error);
        }

    };
    const onSubmitHandler = async() =>{
        try {
            let payload = {
               carData : { year: carData.year,
                rcNumber: carData.rcNumber,
                photos: carData.photos,
                docs: carData.docs,
                // isAcAvailable,
                // isCarrierAvailable
            }

            }
            if(selectedDriver == 'remove'){
                payload.removeDrivers = [carData.DriverCar.User.id]
            } else if(selectedDriver) {
                payload.addDrivers = [selectedDriver]
            }
            const response = await services.Cars.UPDATE_CAR(id, payload);
            console.log(response)
            setToaster({
                type: "success",
                title: "Car Added",
                text: 'Car Added successfully',
                visiblity: "show",
            });
            setTimeout(()=>{
            navigate('/cars')

            },300)
        } catch (error) {
            console.log(error)
            setToaster({
                type: "danger",
                title: "Error Occured",
                text: error.response.data.message,
                visiblity: "show",
            });
            setTimeout(() => {
                setToaster({
                    visiblity: "hide",
                });

            }, 1500)
        }
    } 
    useEffect(()=>{
        getMasterCars();
        getDriverData();
        getCarDetail(id);
    },[])
    return (<>
        <Header/>
        <SideBar/>
            <main class="main">
    
            <Toaster
                type={toaster.type}
                title={toaster.title}
                text={toaster.text}
                visiblity={toaster.visiblity}
            />
    
    
            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                       <DashboardSideBar/>
                        <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="user-profile-card">
                                            <div class="user-profile-card-header">
                                                <h4 class="user-profile-card-title">Edit Car</h4>
                                             
                                            </div>
                                            <div class="user-profile-form">

                                                <div class="row">
                                                <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Select Car</label>
                                                           <select value={carData?.carId} onChange={(e)=>HandleOnChangeValue(e.target.value, 'carId')}  class="form-control">
                                                                <option>Select</option>
                                                                {carsList && carsList.length && carsList.map((item)=> {return (
                                                                     <option value={item.id}>{item.name}</option>
                                                                )})}
                                                           </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Car Model Year</label>
                                                            <input type="text"  onChange={(e)=>HandleOnChangeValue(e.target.value, 'year')}  class="form-control" value={carData?.year}
                                                                placeholder="Car Model Year" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Car RC Number</label>
                                                            <input type="text"  onChange={(e)=>HandleOnChangeValue(e.target.value, 'rcNumber')}  class="form-control" value={carData?.rcNumber}
                                                                placeholder="Car RC Number" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 align-items-center d-flex">
                                                        <div class="form-group mb-0">
                                                            <label class="align-items-center d-flex gap-2" >
                                                               
                                                                <Toggle
                                                                    checked={isAcAvailable}
                                                                    onChange={(e) => setIsAcAvailable(e.target.checked)} />
                                                                <span>Air Conditioner</span>
                                                            </label>
                                                            <label class="align-items-center d-flex gap-2" >
                                                                <Toggle
                                                                    checked={isCarrierAvailable}
                                                                    onChange={(e) => setIsCarrierAvailable(e.target.checked)} />
                                                                <span>Carrier</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Select Driver</label>
                                                           <select onChange={(e)=>setSelectedDriver(e.target.value)}  class="form-control">
                                                                <option>Select</option>
                                                               {carData?.DriverCar?.id && <option value={'remove'}>Remove Driver</option>}
                                                                {driverList && driverList.length && driverList.map((item)=> {return (
                                                                     <option value={item.id}>{item.firstName} {item.lastName}</option>
                                                                )})}
                                                           </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Car Photos</label>
                                                            <input onChange={(e) => uploadBusinessImage(e, 'car')} type="file" class="form-control"
                                                                placeholder="Car Photos" />
                                                        </div>
                                                        {carData?.photos && carData?.photos.length && carData?.photos.map((item)=>{return (
                                                            <img style={{maxHeight: '200px'}} crossorigin="anonymous" src={constant.IMAGE_URL + item} />
                                                        )})
                                                            
                                                        }
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Documents (jpeg only)</label>
                                                            <input onChange={(e) => uploadBusinessImage(e)} type="file" class="form-control"
                                                                placeholder="Car Photos" />
                                                        </div>
                                                        {carData?.docs && carData?.docs.length && carData?.docs.map((item)=>{return (
                                                            <img style={{maxHeight: '200px'}} crossorigin="anonymous" src={constant.IMAGE_URL + item} />
                                                        )})
                                                            
                                                        }
                                                    </div>
                                                  
                                                </div>
                                                <button type="button" onClick={onSubmitHandler}  class="theme-btn my-3"><span
                                                    class="far fa-user"></span> Save Changes</button>

                                            </div>
    
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </main>
        <Footer/>
        </>
        )
}
export default EditCar
import { Link,useNavigate } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import { useEffect, useState } from "react"
import services from "../../services"
import constant from "../../constant";
import Toaster from "../../components/toaster";
let payload = {
    userData:{
        firstName: '',
        countryCode: '+91'
    },
    driverData:{
        drivingLicenceNumber: '',
    }
}
const AddDriver = ()=>{
    let navigate = useNavigate();
    const [driverData, setdriverData] = useState(payload)
    const [toaster, setToaster] = useState({ visiblity: "hide" });

    const HandleOnChangeValue = (e, key, type) => {
        let obj = { ...driverData }
        // console.log(e, key, type)
        if(type == 'userData'){
            obj.userData[key] = e;
        } else {
            obj.driverData[key] = e;
        }
            setdriverData({ ...obj })
    }

    const uploadBusinessImage = async (e, type) => {
        const formData = new FormData();
        let categoryImage = e.target.files[0];
        formData.append("file", categoryImage);

        try {
            const response = await services.Cars.UPLOAD_IMAGE(formData);
            console.log(response)
            if (type == 'photo') {
                
                HandleOnChangeValue(response.data.url, 'photo', 'driverData')
            } else {
                let arr = driverData.driverData.documents || []
                arr.push(response.data.url)
                HandleOnChangeValue(arr, 'documents','driverData')
            }
        } catch (error) {
            console.log(error);
        }

    };
    const onSubmitHandler = async() =>{
        try {
            const response = await services.Vendor.ADD_DRIVER(driverData);
            console.log(response)
            setToaster({
                type: "success",
                title: "Driver Added",
                text: 'Driver Added successfully',
                visiblity: "show",
            });
            setTimeout(()=>{
            navigate('/drivers')

            },300)
        } catch (error) {
            console.log(error)
            setToaster({
                type: "danger",
                title: "Error Occured",
                text: error.response.data.message,
                visiblity: "show",
            });
            setTimeout(() => {
                setToaster({
                    visiblity: "hide",
                });

            }, 1500)
        }
    }
    return (<>
        <Header/>
        <SideBar/>
            <main class="main">
    
            <Toaster
                type={toaster.type}
                title={toaster.title}
                text={toaster.text}
                visiblity={toaster.visiblity}
            />
    
    
            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                       <DashboardSideBar/>
                        <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="user-profile-card">
                                            <div class="user-profile-card-header">
                                                <h4 class="user-profile-card-title">Add Driver</h4>
                                             
                                            </div>
                                            <div class="user-profile-form">

                                                <div class="row">
                                                
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>First Name</label>
                                                            <input type="text"  onChange={(e)=>HandleOnChangeValue(e.target.value, 'firstName', 'userData')}  class="form-control" value={driverData?.firstName}
                                                                placeholder="First Name" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Last Name</label>
                                                            <input type="text"  onChange={(e)=>HandleOnChangeValue(e.target.value, 'lastName', 'userData')}  class="form-control" value={driverData?.lastName}
                                                                placeholder="Last Name" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Phone Number</label>
                                                            <input type="number"  onChange={(e)=>HandleOnChangeValue(e.target.value, 'phoneNumber', 'userData')}  class="form-control" value={driverData?.userData?.phoneNumber}
                                                                placeholder="Phone Number" />
                                                                {(driverData?.userData?.phoneNumber?.length < 10 || driverData?.userData?.phoneNumber?.length > 10) && <small className="text-danger">Please enter valid aadhar number</small>}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Email</label>
                                                            <input type="email"  onChange={(e)=>HandleOnChangeValue(e.target.value, 'email', 'userData')}  class="form-control" value={driverData?.email}
                                                                placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Gender</label>
                                                            <select  onChange={(e)=>HandleOnChangeValue(e.target.value, 'gender', 'userData')}  class="form-control" value={driverData?.gender}>
                                                                <option value={'male'}>Male</option>
                                                                <option value={'female'}>Female</option>
                                                                <option value={'other'}>Other</option>
                                                                </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>AadharNumber</label>
                                                            <input type="number"  onChange={(e)=>HandleOnChangeValue(e.target.value, 'aadharNumber', 'driverData')}  class="form-control" value={driverData?.aadharNumber}
                                                                placeholder="AadharNumber" />
                                                                {(driverData?.driverData?.aadharNumber?.length < 12 || driverData?.driverData?.aadharNumber?.length > 12) && <small className="text-danger">Please enter valid aadhar number</small>}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Driving License Number</label>
                                                            <input type="text"  onChange={(e)=>HandleOnChangeValue(e.target.value, 'drivingLicenceNumber', 'driverData')}  class="form-control" value={driverData?.drivingLicenceNumber}
                                                                placeholder="Car RC Number" />
                                                        </div>
                                                    </div>
                                                 
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Photos</label>
                                                            <input onChange={(e) => uploadBusinessImage(e, 'photo')} type="file" class="form-control"
                                                                placeholder="Photo" />
                                                        </div>
                                                        {driverData?.driverData?.photo &&
                                                            <img width={'300px'} crossorigin="anonymous" src={constant.IMAGE_URL + driverData?.driverData?.photo} />
    
                                                            
                                                        }
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Documents (jpeg only)</label>
                                                            <input onChange={(e) => uploadBusinessImage(e,'documents')} type="file" class="form-control"
                                                                placeholder="Car Photos" />
                                                        </div>
                                                        {driverData?.driverData?.documents && driverData?.driverData?.documents.length && driverData?.driverData?.documents.map((item)=>{return (
                                                            <img width={'300px'} crossorigin="anonymous" src={constant.IMAGE_URL + item} />
                                                        )})
                                                            
                                                        }
                                                    </div>
                                                  
                                                </div>
                                                <button type="button" onClick={onSubmitHandler}  class="theme-btn my-3"><span
                                                    class="far fa-user"></span> Save Changes</button>

                                            </div>
    
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </main>
        <Footer/>
        </>
        )
}
export default AddDriver
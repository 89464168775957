import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css'
import Login from "./views/login";
import Register from "./views/register";
import TaxiDetail from "./views/taxiListing/detail";
import MyBookings from "./views/mybookings";
import OrderDetails from "./views/mybookings/detail";
import Dashboard from "./views/dashboard";
import MyProfile from "./views/myProfile";
import Payments from "./views/payments";
import Settings from "./views/setting";
import Terms from "./views/terms";
import Privacy from "./views/privacy";
import CarDetails from "./views/mycars/detail";
import MyCars from "./views/mycars";
import AddCar from "./views/mycars/addcar";
import Drivers from "./views/driver";
import DriverDetails from "./views/driver/detail";
import EditCar from "./views/mycars/editcar";
import AddDriver from "./views/driver/addDriver";
import ViewProfile from "./views/myProfile/viewProfile";
import MyRatings from "./views/ratings";
import { App as CapacitorApp } from '@capacitor/app';
import { useEffect } from "react";

CapacitorApp.addListener('backButton', ({canGoBack}) => {
  if(!canGoBack){
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});
function App() {
  // useEffect(()=>{})
  const isLoggedIn = localStorage.getItem('access_token')
  return (
    <BrowserRouter>
      <Routes>
        {isLoggedIn ? <>
          <Route path="register" element={<Register />} />
          <Route path="mybookings" element={<MyBookings />} />
          <Route index element={<Dashboard />} />
          <Route path="myprofile" element={<MyProfile />} />
          <Route path="profile" element={<ViewProfile />} />
          <Route path="payments" element={<Payments />} />
          <Route path="settings" element={<Settings />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="taxi/:id" element={<TaxiDetail />} />
          <Route path="booking/:id" element={<OrderDetails />} />
          <Route path="cars" element={<MyCars />} />
          <Route path="car/:id" element={<CarDetails />} />
          <Route path="car/edit/:id" element={<EditCar />} />
          <Route path="addcar" element={<AddCar />} />
          <Route path="drivers" element={<Drivers />} />
          <Route path="driver/:id" element={<DriverDetails />} /> 
          <Route path="adddriver" element={<AddDriver />} /> 
          <Route path="ratings" element={<MyRatings />} /> 
          {/* <Route path="*" element={<Navigate to={'/'}/>} /> */}
          </> : 
          <>
          <Route index element={<Login />} />
          <Route path="*" element={<Navigate to={'/'}/>} />
          </>}


        <Route path="*" element={<div>404</div>} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;

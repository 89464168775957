const SideBar = () => {
    
    return (
        <div className="sidebar-popup">
            <div className="sidebar-wrapper">
                <div className="sidebar-content">
                    <button type="button" className="close-sidebar-popup"><i className="far fa-xmark"></i></button>
                    <div className="sidebar-logo">
                        <img src="/assets/img/logo/logo.png" />
                    </div>
                    <div className="sidebar-about">
                        <h4>About Us</h4>
                        <p>There are many variations of passages available sure there majority have suffered alteration in
                            some form by injected humour or randomised words which don't look even slightly believable.</p>
                    </div>
                    <div className="sidebar-contact">
                        <h4>Contact Info</h4>
                        <ul>
                            <li>
                                <h6>Email</h6>
                                <a
                                    href="https://live.themewild.com/cdn-cgi/l/email-protection#a8c1c6cec7e8cdd0c9c5d8c4cd86cbc7c5"><i
                                        className="far fa-envelope"></i><span className="__cf_email__"
                                            data-cfemail="a0c9cec6cfe0c5d8c1cdd0ccc58ec3cfcd">[email&#160;protected]</span></a>
                            </li>
                            <li>
                                <h6>Phone</h6>
                                <a href="tel:+21236547898"><i className="far fa-phone"></i>+2 123 654 7898</a>
                            </li>
                            <li>
                                <h6>Address</h6>
                                <a href="#"><i className="far fa-location-dot"></i>25/ Milford Road, New York</a>
                            </li>
                        </ul>
                    </div>
                    <div className="sidebar-social">
                        <h4>Follow Us</h4>
                        <a href="#"><i className="fab fa-facebook"></i></a>
                        <a href="#"><i className="fab fa-twitter"></i></a>
                        <a href="#"><i className="fab fa-instagram"></i></a>
                        <a href="#"><i className="fab fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
    }
    export default SideBar